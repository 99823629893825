@import "valiable";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #ffffff;

  @media print {
    zoom: 0.5;
    -webkit-print-color-adjust: exact;
  }
}
