.fileDropzone {
  cursor: pointer;
  margin-top:30px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  line-height: 48px;
  max-width:400px;
  width:100%;
  height: 400px;
  background-color: #DBE2E8 ;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top:0;
    left:0;
    width:400px;
    height: 400px;
    object-fit: cover;
  }

  &.dragActive {
    img {
      opacity: .5;
    }
  }


  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 20px;
    position: absolute;
    border-radius: 5px;
    position: absolute;
    top:0;
    left:0;
    max-width:400px;
    width:100%;
    height: 400px;

    &.dropArea {
      border:white 5px dashed;
    }
  }

  span {
    white-space: nowrap;
    font-size:16px;
  }

  :hover {
    background-color: #eee;
  }

}